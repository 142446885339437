import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const NewPickup = Loadable(lazy(() => import('./new-pickup/NewPickup')));
const CreatePickup = Loadable(lazy(() => import('./create-pickup/CreatePickup')));
const EditPickup = Loadable(lazy(() => import('./edit-pickup/NewPickup')));
const SearchUpdate = Loadable(lazy(() => import('./search-update-pickup/SearchUpdate')));
const PickupInformation = Loadable(lazy(() => import('./pickup-information/pickupInformation')));
const QuickUpdate = Loadable(lazy(() => import('./quick-update-pickup/SearchUpdate')));
const CurrentStock = Loadable(lazy(() => import('./stock/BranchStock')));
const List = Loadable(lazy(() => import('./delivery-cost/List')));
const Add = Loadable(lazy(() => import('./delivery-cost/add/Add')));
const Edit = Loadable(lazy(() => import('./delivery-cost/edit/Edit')));
const RList = Loadable(lazy(() => import('./rider-commission/List')));
const RAdd = Loadable(lazy(() => import('./rider-commission/add/Add')));
const REdit = Loadable(lazy(() => import('./rider-commission/edit/Edit')));
const MList = Loadable(lazy(() => import('./merchant-list/List')));
const INVOICEList = Loadable(lazy(() => import('./merchant-invoice-list/List')));
const DETAILS = Loadable(lazy(() => import('./merchant-list/details/DETAILS')));
const MEdit = Loadable(lazy(() => import('./merchant-list/edit/Edit')));
const RIList = Loadable(lazy(() => import('./rider-list/List')));
const OTPList = Loadable(lazy(() => import('./otp-list/List')));
const RDETAILS = Loadable(lazy(() => import('./rider-list/details/DETAILS')));
const RIEdit = Loadable(lazy(() => import('./rider-list/edit/Edit')));
const AMOUNTDETAILS = Loadable(lazy(() => import('./rider-list/total-amount-list/List')));
const COLLECTIONTAILS = Loadable(lazy(() => import('./rider-list/collection-amount-list/List')));
const RIDERCOMMITIONDETAILS = Loadable(lazy(() => import('./rider-list/rider-commition-withdraw-list/List')));
const RPAYDETAILS = Loadable(lazy(() => import('./rider-list/pay-amount-list/List')));
const MPAYDETAILS = Loadable(lazy(() => import('./merchant-list/pay-amount-list/List')));
const MCOLLECTIONDETAILS = Loadable(lazy(() => import('./merchant-list/merchant-collection-withdraw-list/List')));
const AMOUNTDETAILSM = Loadable(lazy(() => import('./merchant-list/total-amount-list/List')));
const AMOUNTDETAILSMR = Loadable(lazy(() => import('./merchant-list/total-return-amount-list/List')));
const INVOICE = Loadable(lazy(() => import('./invoice')));
const INVOICE2 = Loadable(lazy(() => import('./invoice2')));
const INVOICE3 = Loadable(lazy(() => import('./invoice3')));
const INVOICE4 = Loadable(lazy(() => import('./invoice4')));
const INVOICE5 = Loadable(lazy(() => import('./invoice5')));

const courierRoutes = [
  {// here
    path: '/courier/new-pickup',
    element: <NewPickup />,
  },
  {// here
    path: '/courier/create-pickup',
    element: <CreatePickup />,
  }, 
  {// here
    path: '/courier/edit-pickup',
    element: <EditPickup />,
  },
    {// here
      path: '/courier/search-update-pickup',
      element: <SearchUpdate />,
    }, 
    {// here
      path: '/courier/pickup-information',
      element: <PickupInformation />,
    }, 
    {// here
      path: '/courier/quick-update-pickup',
      element: <QuickUpdate />,
    }
    , 
    {// here
      path: '/courier/stock-branch',
      element: <CurrentStock />,
    }
    , 
    {// here
      path: '/courier/delivery-cost',
      element: <List />,
    }
    , 
    {// here
      path: '/courier/delivery-cost/add',
      element: <Add />,
    }
    , 
    {// here
      path: '/courier/delivery-cost/edit',
      element: <Edit />,
    }
    , 
    {// here
      path: '/courier/rider-commission',
      element: <RList />,
    }
    , 
    {// here
      path: '/courier/rider-commission/add',
      element: <RAdd />,
    }
    , 
    {// here
      path: '/courier/rider-commission/edit',
      element: <REdit />,
    }
    , 
    {// here
      path: '/courier/merchant-list',
      element: <MList />,
    }
    , 
    {// here
      path: '/courier/merchant-invoice-list',
      element: <INVOICEList />,
    }
    , 
    {// here
      path: '/courier/merchant-list/details',
      element: <DETAILS />,
    }
    , 
    {// here
      path: '/courier/merchant-list/edit',
      element: <MEdit />,
    }
    , 
    {// here
      path: '/courier/rider-list',
      element: <RIList />,
    }
    , 
    {// here
      path: '/courier/otp-list',
      element: <OTPList />,
    }
    , 
    {// here
      path: '/courier/rider-list/details',
      element: <RDETAILS />,
    }
    , 
    {// here
      path: '/courier/rider-list/edit',
      element: <RIEdit />,
    }
    , 
    {// here
      path: '/courier/rider-list/total-amount-list',
      element: <AMOUNTDETAILS />,
    }
    , 
    {// here
      path: '/courier/rider-list/rider-commition-withdraw-list',
      element: <RIDERCOMMITIONDETAILS />,
    }
    , 
    {// here
      path: '/courier/rider-list/collection-amount-list',
      element: <COLLECTIONTAILS />,
    }
    , 
    {// here
      path: '/courier/invoice',
      element: <INVOICE />,
    }
    , 
    {// here
      path: '/courier/invoice2',
      element: <INVOICE2 />,
    }
    , 
    {// here
      path: '/courier/invoice3',
      element: <INVOICE3 />,
    }, 
    {// here
      path: '/courier/merchant-list/total-return-amount-list',
      element: <AMOUNTDETAILSMR />,
    }
    , 
    {// here
      path: '/courier/invoice4',
      element: <INVOICE4 />,
    }
    , 
     {// here
      path: '/courier/invoice5',
      element: <INVOICE5 />,
    }
    ,
    {// here
      path: '/courier/rider-list/pay-amount-list',
      element: <RPAYDETAILS />,
    }
    , 
    {// here
      path: '/courier/merchant-list/pay-amount-list',
      element: <MPAYDETAILS />,
    }
    , 
    {// here
      path: '/courier/merchant-list/merchant-collection-withdraw-list',
      element: <MCOLLECTIONDETAILS />,
    }
    , 
    {// here
      path: '/courier/merchant-list/total-amount-list',
      element: <AMOUNTDETAILSM />,
    }
];

export default courierRoutes;
