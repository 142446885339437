import useSettings from 'app/hooks/useSettings';

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    
   <img width="150" src='/assets/images/logo.png'/>
    
  );
};

export default MatxLogo;
