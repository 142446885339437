import Loadable from 'app/components/Loadable';
import { lazy } from 'react';


const Edit = Loadable(lazy(() => import('./Edit')));
 

const editRoutes = [
  {// here
    path: '/edit',
    element: <Edit/>,
  }, 
];

export default editRoutes;
