export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { label: 'Documents', type: 'label' },
  {
    name: 'Courier Manage',
    icon: 'assistant',
    children: [
      { name: 'New Pickup', icon: 'format_italic', path: '/courier/new-pickup' },
      // { name: 'Create Pickup', icon: 'format_italic', path: '/courier/create-pickup' },
      { name: 'Stock Branch', icon: 'format_italic', path: '/courier/stock-branch' },
      { name: 'Search & Status', icon: 'format_italic', path: '/courier/search-update-pickup' },
      { name: 'Quick Update', icon: 'format_italic', path: '/courier/quick-update-pickup' }, ],
  },
  {
    name: 'Issue',
    icon: 'brush',
    path: '/issue',
  },
  {
    name: 'All Report',
    icon: 'trending_up',
    children: [
      { name: 'Pickup Report', icon: 'format_italic', path: '/report/pickup' },
      { name: 'Shipment Report', icon: 'format_italic', path: '/report/shipment' },
    ],
  },
  { label: 'Settings', type: 'label' },
  {
    name: 'Setup & Configuration',
    icon: 'launch',
    type: 'extLink',
    path: process.env.REACT_APP_BACKOFFICE_URL,
  },
  {
    name: 'Delivery Cost',
    icon: 'launch',
    path: '/courier/delivery-cost',
  },
  {
    name: 'Rider Commission',
    icon: 'launch',
    path: '/courier/rider-commission',
  },
  {
    name: 'Merchant List',
    icon: 'launch',
    path: '/courier/merchant-list',
  },
  {
    name: 'Rider List',
    icon: 'launch',
    path: '/courier/rider-list',
  },
  {
    name: 'Otp List',
    icon: 'launch',
    path: '/courier/otp-list',
  },
  {
    name: 'Invoice List',
    icon: 'launch',
    path: '/courier/merchant-invoice-list',
  }
];

// export const navigations2 = [
//   { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
//   { name: 'New Pickup', icon: 'format_italic', path: '/courier/new-pickup' },
//   { name: 'Search & Status', icon: 'format_italic', path: '/courier/search-update-pickup' },
//   {
//     name: 'Invoice List',
//     icon: 'launch',
//     path: '/courier/merchant-invoice-list',
//   },
//   {
//     name: 'Setup & Configuration',
//     icon: 'launch',
//     type: 'extLink',
//     path: process.env.REACT_APP_BACKOFFICE_URL,
//   }
// ];

// export const navigations3 = [
//   { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
//   { label: 'Documents', type: 'label' },
//   {
//     name: 'Courier Manage',
//     icon: 'assistant',
//     children: [
//       // { name: 'New Pickup', icon: 'format_italic', path: '/courier/new-pickup' },
//       // { name: 'Create Pickup', icon: 'format_italic', path: '/courier/create-pickup' },
//       { name: 'Stock Branch', icon: 'format_italic', path: '/courier/stock-branch' },
//       { name: 'Search & Status', icon: 'format_italic', path: '/courier/search-update-pickup' },
//       { name: 'Quick Update', icon: 'format_italic', path: '/courier/quick-update-pickup' }, ],
//   },
//   {
//     name: 'Issue',
//     icon: 'brush',
//     path: '/issue',
//   },
//   {
//     name: 'All Report',
//     icon: 'trending_up',
//     children: [
//       { name: 'Pickup Report', icon: 'format_italic', path: '/report/pickup' },
//       { name: 'Shipment Report', icon: 'format_italic', path: '/report/shipment' },
     
//     ],
//   },
//   { label: 'Settings', type: 'label' },
//   {
//     name: 'Delivery Cost',
//     icon: 'launch',
//     path: '/courier/delivery-cost',
//   },
//   {
//     name: 'Rider Commission',
//     icon: 'launch',
//     path: '/courier/rider-commission',
//   },
//   {
//     name: 'Merchant List',
//     icon: 'launch',
//     path: '/courier/merchant-list',
//   },
//   {
//     name: 'Rider List',
//     icon: 'launch',
//     path: '/courier/rider-list',
//   },
//   {
//     name: 'Otp List',
//     icon: 'launch',
//     path: '/courier/otp-list',
//   },
// ];

